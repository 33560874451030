var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{scopedSlots:_vm._u([{key:"navbar-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.sourceObject.results ? _vm.sourceObject.results.phone_number : " ")+" ")]},proxy:true},{key:"navbar-btn-wrap",fn:function(){return [(_vm.$store.getters.getCurrentRole == 70)?_c('DeleteUserPopup',{attrs:{"name":_vm.deletePermission ? _vm.deletePermission.btnTitle : 'Пользователя',"diller-organization-id":_vm.$route.params.dealerId,"object-id":_vm.$route.params.objectId,"group-id":_vm.$route.params.userGroupsId,"organization-id":_vm.$route.params.companyId,"kindergarten-id":_vm.$route.params.kindergartenId,"user-id":_vm.$route.params.id,"title":_vm.sourceObject.results ? _vm.sourceObject.results.phone_number : '',"deletePermission":_vm.deletePermission,"description":_vm.deletePermission
            ? _vm.deletePermission.descriptions
            : 'Удалить пользователя из:'}}):_vm._e(),(
          !(_vm.sourceObject.results && _vm.sourceObject.results.is_active) &&
            _vm.$store.getters.getCurrentRole == 70
        )?_c('UnBlockPopup',{attrs:{"name":"Пользователя","title":_vm.sourceObject.results ? _vm.sourceObject.results.phone_number : '',"un-block-function":_vm.unBanUser}}):_vm._e(),(
          _vm.$store.getters.getCurrentRole == 70 &&
            _vm.sourceObject.results &&
            _vm.sourceObject.results.is_active
        )?_c('BlockPopup',{attrs:{"name":"Пользователя","title":_vm.sourceObject.results ? _vm.sourceObject.results.phone_number : '',"block-function":_vm.banUser}}):_vm._e(),_c('DeletePopupAdmin',{attrs:{"title":"Аккаунт","description":"Внимание!\n\nПроизойдет полное удаление пользователя из системы и всех с ним связанных данных. Удалить?","dialog":_vm.dialog,"delete-function":_vm.deleteFunction},on:{"closeDeletePopup":_vm.closeDeletePopup}}),(_vm.urlQuery && _vm.urlQuery.id)?_c('router-link',{staticClass:"btn btn-edit btn_color_grey",attrs:{"to":_vm.urlEdit + _vm.urlQuery.id}},[_vm._v("редактировать")]):_vm._e()]},proxy:true}])},[_c('SlideLayout',{attrs:{"tabs":_vm.slideTabs},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticClass:"keep__title"},[_c('p',{staticClass:"keep__text"},[_vm._v("Общая информация")])]),(_vm.sourceObject.results)?_c('BasicTableWithoutHeader',{attrs:{"table-data":_vm.getDataRequisites},scopedSlots:_vm._u([{key:"item.3.value",fn:function({item}){return [_c('p',{style:(item.value
                  ? 'color: #95C23D !important'
                  : 'color: #EB5C6D !important')},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"item.4.value",fn:function({item}){return [_c('p',{style:(item.value == 'Не заблокирован'
                  ? 'color: #767d92 !important'
                  : 'color: #EB5C6D !important')},[_vm._v(" "+_vm._s(item.value)+" ")])]}},(_vm.currentRole == 31)?{key:"item.1.value",fn:function({}){return [_c('div',{staticClass:"d-flex"},[_c('ValidationInputField',{staticClass:"mr-4 d-flex",attrs:{"mask":"###.#####","disabled":_vm.input_disabled ||
                    (_vm.code && _vm.code.length == 9 && _vm.code_isEdit == false)},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"border-radius":"50px","color":"#ffffff","padding":"5px 15px"},style:({
                    background:
                      _vm.user_payments === 'paid'
                        ? '#95C23D'
                        : _vm.user_payments === 'not-paid'
                        ? '#EB5C6D'
                        : '#6D778E'
                  })},[_c('p',{staticClass:"text-align_center"},[_vm._v(" "+_vm._s(_vm.user_payments === "paid" ? "Оплачена" : _vm.user_payments === "not-paid" ? "Не оплачена" : "Ожидает ввода карты")+" ")])])]),_c('div',{staticClass:"ml-auto"},[(_vm.currentRole == 31)?[_c('v-btn',{staticClass:"btn btn-green",attrs:{"type":"submit","disabled":_vm.input_disabled ||
                        (_vm.code && _vm.code.length == 9 && _vm.code_isEdit == false)},on:{"click":_vm.editParent}},[_vm._v(" Сохранить ")])]:_vm._e()],2)],1)]}}:null,(
              !_vm.showRestore &&
                !_vm.showConfirmation &&
                _vm.currentUser &&
                !_vm.restoreUser &&
                _vm.currentRole == 70
            )?{key:"item.7.value",fn:function({}){return [_c('v-btn',{staticClass:"btn btn-delete btn_color_red",attrs:{"type":"submit","color":"#EB5C6D"},on:{"click":_vm.dialogShow}},[_vm._v(" Удалить ")])]}}:null],null,true)}):_vm._e()]},proxy:true},(_vm.$store.getters.getCurrentRole !== 21)?{key:"objects",fn:function(){return [_c('UsersObject')]},proxy:true}:null,(_vm.$store.getters.getCurrentRole !== 21)?{key:"payments",fn:function(){return [_c('UsersPayments')]},proxy:true}:null,(_vm.$store.getters.getCurrentRole !== 21)?{key:"pass_log",fn:function(){return [_c('UserPassageLog')]},proxy:true}:null,(_vm.$store.getters.getCurrentRole !== 21)?{key:"access",fn:function(){return [_c('UsersAccess')]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }